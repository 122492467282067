
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as confirm3LgBvZ_w_45DyciCMjgrodMMPWLhB4sGtSLrsz8cgrMtcMeta } from "/code/frontend/pages/auth/confirm.vue?macro=true";
import { default as forgotPasswordyYNjYAAdVtnOCgCx5sTrQhd0L_hUPfpWcqmPHYh5tuIMeta } from "/code/frontend/pages/auth/forgotPassword.vue?macro=true";
import { default as loginZvZRl76LTX5JUjUqypD94VaDRIJfWUrjb3r2euJuwj0Meta } from "/code/frontend/pages/auth/login.vue?macro=true";
import { default as logoutOgF6WQqh_MWknCbnUpStbgku4v29vDGhgc_sC40JcccMeta } from "/code/frontend/pages/auth/logout.vue?macro=true";
import { default as registerQu7w7mr2Zkmk4i_9ttWOLOPVM9IGTqQcSWY99ginyngMeta } from "/code/frontend/pages/auth/register.vue?macro=true";
import { default as calendarjM_TIYzPsyT7R8yJe2Y97jGpSojXgbelx0oJAQV3lLcMeta } from "/code/frontend/pages/dispatcher/calendar.vue?macro=true";
import { default as indexa6ThR_qAONsoAAeTDDy50bKdZ9rdlMX3pDZn6DvSBnIMeta } from "/code/frontend/pages/dispatcher/index.vue?macro=true";
import { default as schedulerQJcrO1Wu3Xl5XI3adWZFua63O21huDzIYHvrLvp9AYQMeta } from "/code/frontend/pages/dispatcher/scheduler.vue?macro=true";
import { default as settingssXXis2lBYQVfxV0BUjdDauOOfFb6qyaKLvB5n6SowfoMeta } from "/code/frontend/pages/dispatcher/settings.vue?macro=true";
import { default as socketioFcEqLw4z7QG5tjNxCT9eVAQiNFcAtHuAyLupPnrGGagMeta } from "/code/frontend/pages/dispatcher/socketio.vue?macro=true";
import { default as statisticsR37tKlGlvZhSIvjz2b1s_Ehcp5L_45ETRTmW9K5mPTqD4Meta } from "/code/frontend/pages/dispatcher/statistics.vue?macro=true";
import { default as terminaln3hvQrpof89ubpdU6xkvDtkJxgQd05opXHaDyS5NLEwMeta } from "/code/frontend/pages/dispatcher/terminal.vue?macro=true";
import { default as translatorrQqzWmx_YtffN_45Uwjpe9aKy_45LTdgvGgGL7EZCciDjxgMeta } from "/code/frontend/pages/dispatcher/translator.vue?macro=true";
import { default as waypointsLHitFmgZg1MT0wdQMRDjJKlzVCCI0g3AaPmL4j9AP1UMeta } from "/code/frontend/pages/dispatcher/waypoints.vue?macro=true";
import { default as docsoR9VPIJssa6yigej0VDe6uUYIsO0tVlIp9Vte2FUNiEMeta } from "/code/frontend/pages/docs.vue?macro=true";
import { default as arrivalsqJMStbVbssvs_45K6RZkKcbjv9z4f3MJA_45eHn0mEHFSJEMeta } from "/code/frontend/pages/driver/arrivals.vue?macro=true";
import { default as chatyqzOOwvMW5XI1c3GbO8H6bXVa5R6MAubxn3lKRgl_45dYMeta } from "/code/frontend/pages/driver/chat.vue?macro=true";
import { default as cmrjI4AAVPXqNi8PTkOrwqBZQVmP4OEdEmzS25Ec_45dyIfwMeta } from "/code/frontend/pages/driver/cmr.vue?macro=true";
import { default as historyqtE1i_45FR9bqJaLzV64lRe10Ebmtfrxg5ii3hDF97xNcMeta } from "/code/frontend/pages/driver/history.vue?macro=true";
import { default as profile5DXplpLxYpMWWQ2eZLYHbHpmS5PKTCDx_OtdB4glt9IMeta } from "/code/frontend/pages/driver/profile.vue?macro=true";
import { default as indexQKwuvAfvtDq8mnm97PVYZV2x3o4eBADJzNo3JiOVJAIMeta } from "/code/frontend/pages/index.vue?macro=true";
import { default as policyez8FdMZml1zcUKpKXV9VpsiWwSM87Z65rC9Jlv_45Rw74Meta } from "/code/frontend/pages/privacy/policy.vue?macro=true";
import { default as qrvZrw0moL6TdUzfnbNZQfT1273qogmk8k2tAqkD_GS2sMeta } from "/code/frontend/pages/qr.vue?macro=true";
export default [
  {
    name: "auth-confirm",
    path: "/auth/confirm",
    meta: confirm3LgBvZ_w_45DyciCMjgrodMMPWLhB4sGtSLrsz8cgrMtcMeta || {},
    component: () => import("/code/frontend/pages/auth/confirm.vue")
  },
  {
    name: "auth-forgotPassword",
    path: "/auth/forgotPassword",
    meta: forgotPasswordyYNjYAAdVtnOCgCx5sTrQhd0L_hUPfpWcqmPHYh5tuIMeta || {},
    component: () => import("/code/frontend/pages/auth/forgotPassword.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginZvZRl76LTX5JUjUqypD94VaDRIJfWUrjb3r2euJuwj0Meta || {},
    component: () => import("/code/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutOgF6WQqh_MWknCbnUpStbgku4v29vDGhgc_sC40JcccMeta || {},
    component: () => import("/code/frontend/pages/auth/logout.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: registerQu7w7mr2Zkmk4i_9ttWOLOPVM9IGTqQcSWY99ginyngMeta || {},
    component: () => import("/code/frontend/pages/auth/register.vue")
  },
  {
    name: "dispatcher-calendar",
    path: "/dispatcher/calendar",
    meta: calendarjM_TIYzPsyT7R8yJe2Y97jGpSojXgbelx0oJAQV3lLcMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/calendar.vue")
  },
  {
    name: "dispatcher",
    path: "/dispatcher",
    meta: indexa6ThR_qAONsoAAeTDDy50bKdZ9rdlMX3pDZn6DvSBnIMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/index.vue")
  },
  {
    name: "dispatcher-scheduler",
    path: "/dispatcher/scheduler",
    meta: schedulerQJcrO1Wu3Xl5XI3adWZFua63O21huDzIYHvrLvp9AYQMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/scheduler.vue")
  },
  {
    name: "dispatcher-settings",
    path: "/dispatcher/settings",
    meta: settingssXXis2lBYQVfxV0BUjdDauOOfFb6qyaKLvB5n6SowfoMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/settings.vue")
  },
  {
    name: "dispatcher-socketio",
    path: "/dispatcher/socketio",
    meta: socketioFcEqLw4z7QG5tjNxCT9eVAQiNFcAtHuAyLupPnrGGagMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/socketio.vue")
  },
  {
    name: "dispatcher-statistics",
    path: "/dispatcher/statistics",
    meta: statisticsR37tKlGlvZhSIvjz2b1s_Ehcp5L_45ETRTmW9K5mPTqD4Meta || {},
    component: () => import("/code/frontend/pages/dispatcher/statistics.vue")
  },
  {
    name: "dispatcher-terminal",
    path: "/dispatcher/terminal",
    meta: terminaln3hvQrpof89ubpdU6xkvDtkJxgQd05opXHaDyS5NLEwMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/terminal.vue")
  },
  {
    name: "dispatcher-translator",
    path: "/dispatcher/translator",
    meta: translatorrQqzWmx_YtffN_45Uwjpe9aKy_45LTdgvGgGL7EZCciDjxgMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/translator.vue")
  },
  {
    name: "dispatcher-waypoints",
    path: "/dispatcher/waypoints",
    meta: waypointsLHitFmgZg1MT0wdQMRDjJKlzVCCI0g3AaPmL4j9AP1UMeta || {},
    component: () => import("/code/frontend/pages/dispatcher/waypoints.vue")
  },
  {
    name: "docs",
    path: "/docs",
    meta: docsoR9VPIJssa6yigej0VDe6uUYIsO0tVlIp9Vte2FUNiEMeta || {},
    component: () => import("/code/frontend/pages/docs.vue")
  },
  {
    name: "driver-arrivals",
    path: "/driver/arrivals",
    meta: arrivalsqJMStbVbssvs_45K6RZkKcbjv9z4f3MJA_45eHn0mEHFSJEMeta || {},
    component: () => import("/code/frontend/pages/driver/arrivals.vue")
  },
  {
    name: "driver-chat",
    path: "/driver/chat",
    meta: chatyqzOOwvMW5XI1c3GbO8H6bXVa5R6MAubxn3lKRgl_45dYMeta || {},
    component: () => import("/code/frontend/pages/driver/chat.vue")
  },
  {
    name: "driver-cmr",
    path: "/driver/cmr",
    meta: cmrjI4AAVPXqNi8PTkOrwqBZQVmP4OEdEmzS25Ec_45dyIfwMeta || {},
    component: () => import("/code/frontend/pages/driver/cmr.vue")
  },
  {
    name: "driver-history",
    path: "/driver/history",
    meta: historyqtE1i_45FR9bqJaLzV64lRe10Ebmtfrxg5ii3hDF97xNcMeta || {},
    component: () => import("/code/frontend/pages/driver/history.vue")
  },
  {
    name: "driver-profile",
    path: "/driver/profile",
    meta: profile5DXplpLxYpMWWQ2eZLYHbHpmS5PKTCDx_OtdB4glt9IMeta || {},
    component: () => import("/code/frontend/pages/driver/profile.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexQKwuvAfvtDq8mnm97PVYZV2x3o4eBADJzNo3JiOVJAIMeta || {},
    component: () => import("/code/frontend/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy/policy",
    meta: policyez8FdMZml1zcUKpKXV9VpsiWwSM87Z65rC9Jlv_45Rw74Meta || {},
    component: () => import("/code/frontend/pages/privacy/policy.vue")
  },
  {
    name: "qr",
    path: "/qr",
    meta: qrvZrw0moL6TdUzfnbNZQfT1273qogmk8k2tAqkD_GS2sMeta || {},
    component: () => import("/code/frontend/pages/qr.vue")
  }
]