
// @ts-nocheck
import locale_cs_46json_e1e595cf from "#nuxt-i18n/e1e595cf";
import locale_en_46json_38e05b0a from "#nuxt-i18n/38e05b0a";
import locale_sk_46json_0f45fd5e from "#nuxt-i18n/0f45fd5e";
import locale_pl_46json_feaa9333 from "#nuxt-i18n/feaa9333";
import locale_hu_46json_1cbc1986 from "#nuxt-i18n/1cbc1986";
import locale_de_46json_a8066b4f from "#nuxt-i18n/a8066b4f";

export const localeCodes =  [
  "cs",
  "en",
  "sk",
  "pl",
  "hu",
  "de"
]

export const localeLoaders = {
  cs: [
    {
      key: "locale_cs_46json_e1e595cf",
      load: () => Promise.resolve(locale_cs_46json_e1e595cf),
      cache: true
    }
  ],
  en: [
    {
      key: "locale_en_46json_38e05b0a",
      load: () => Promise.resolve(locale_en_46json_38e05b0a),
      cache: true
    }
  ],
  sk: [
    {
      key: "locale_sk_46json_0f45fd5e",
      load: () => Promise.resolve(locale_sk_46json_0f45fd5e),
      cache: true
    }
  ],
  pl: [
    {
      key: "locale_pl_46json_feaa9333",
      load: () => Promise.resolve(locale_pl_46json_feaa9333),
      cache: true
    }
  ],
  hu: [
    {
      key: "locale_hu_46json_1cbc1986",
      load: () => Promise.resolve(locale_hu_46json_1cbc1986),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_46json_a8066b4f",
      load: () => Promise.resolve(locale_de_46json_a8066b4f),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "i18n",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "cs",
      label: "čeština",
      files: [
        "/code/frontend/i18n/locales/cs.json"
      ]
    },
    {
      code: "en",
      label: "english",
      files: [
        "/code/frontend/i18n/locales/en.json"
      ]
    },
    {
      code: "sk",
      label: "slovenčina",
      files: [
        "/code/frontend/i18n/locales/sk.json"
      ]
    },
    {
      code: "pl",
      label: "polski",
      files: [
        "/code/frontend/i18n/locales/pl.json"
      ]
    },
    {
      code: "hu",
      label: "magyar",
      files: [
        "/code/frontend/i18n/locales/hu.json"
      ]
    },
    {
      code: "de",
      label: "deutch",
      files: [
        "/code/frontend/i18n/locales/de.json"
      ]
    }
  ],
  defaultLocale: "cs",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: false,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "cs",
    label: "čeština",
    files: [
      {
        path: "/code/frontend/i18n/locales/cs.json",
        cache: undefined
      }
    ]
  },
  {
    code: "en",
    label: "english",
    files: [
      {
        path: "/code/frontend/i18n/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "sk",
    label: "slovenčina",
    files: [
      {
        path: "/code/frontend/i18n/locales/sk.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pl",
    label: "polski",
    files: [
      {
        path: "/code/frontend/i18n/locales/pl.json",
        cache: undefined
      }
    ]
  },
  {
    code: "hu",
    label: "magyar",
    files: [
      {
        path: "/code/frontend/i18n/locales/hu.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    label: "deutch",
    files: [
      {
        path: "/code/frontend/i18n/locales/de.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = true
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/