declare interface ICan {
    change_calendar: boolean;
    change_second_jobs_priorities: boolean;
    change_terminal_settings: boolean;
    chat: boolean;
    create_any_dispatcher: boolean;
    create_main_dispatcher: boolean;
    create_terminal_dispatcher: boolean;
    create_truck_dispatcher: boolean;
    create_waypoint_dispatcher: boolean;
    deactivateUser: boolean;
    define_sections: boolean;
    delete_driver: boolean;
    delete_main_dispatcher: boolean;
    delete_terminal_dispatcher: boolean;
    delete_truck_dispatcher: boolean;
    delete_waypoint_dispatcher: boolean;
    edit_driver: boolean;
    edit_main_dispatcher: boolean;
    edit_terminal_dispatcher: boolean;
    edit_trip_status: boolean;
    edit_truck_dispatcher: boolean;
    edit_waypoint_dispatcher: boolean;
    end_trip: boolean;
    impersonate_drivers: boolean;
    manageRedis: boolean;
    manageScheduler: boolean;
    notify_new_version: boolean;
    set_next_day_jobs: boolean;
    set_second_jobs: boolean;
    switch_terminal: boolean;
    viewDispo: boolean;
    viewWaypoints: boolean;
}

export function useCan(): ICan {
    const { data: authData } = useAuth();
    const rights = authData.value?.rights || [];
    const returnObject = {
        change_calendar: rights.includes("calendar:edit"),
        change_second_jobs_priorities: rights.includes("setup:sections"),
        change_terminal_settings: rights.includes("setup:sections") || rights.includes("setup:read"),
        chat: rights.includes("message"),
        create_any_dispatcher: rights.some((right) => right.endsWith("dispatcher:create")),
        create_main_dispatcher: rights.includes("main_dispatcher:create"),
        create_terminal_dispatcher: rights.includes("terminal_dispatcher:create"),
        create_truck_dispatcher: rights.includes("truck_dispatcher:create"),
        create_waypoint_dispatcher: rights.includes("waypoint_dispatcher:create"),
        deactivateUser: rights.includes("dispo:view"),
        define_sections: rights.includes("setup:sections"),
        delete_driver: rights.includes("driver:delete"),
        delete_main_dispatcher: rights.includes("main_dispatcher:delete"),
        delete_terminal_dispatcher: rights.includes("terminal_dispatcher:delete"),
        delete_truck_dispatcher: rights.includes("truck_dispatcher:delete"),
        delete_waypoint_dispatcher: rights.includes("waypoint_dispatcher:delete"),
        edit_driver: rights.includes("driver:edit"),
        edit_main_dispatcher: rights.includes("main_dispatcher:edit"),
        edit_terminal_dispatcher: rights.includes("terminal_dispatcher:edit"),
        edit_trip_status: rights.includes("trip_status:edit"),
        edit_truck_dispatcher: rights.includes("truck_dispatcher:edit"),
        edit_waypoint_dispatcher: rights.includes("waypoint_dispatcher:edit"),
        end_trip: rights.includes("trip:end"),
        impersonate_drivers: rights.includes("driver:impersonate"),
        manageRedis: rights.includes("admin"),
        manageScheduler: rights.includes("admin"),
        notify_new_version: rights.includes("admin"),
        set_next_day_jobs: rights.includes("setup:sections") || rights.includes("setup:read"),
        set_second_jobs: rights.includes("setup:sections"),
        switch_terminal: rights.includes("setup:sections"),
        viewDispo: rights.includes("dispo:view"),
        viewWaypoints: rights.includes("terminal_waypoints:view")
    };
    return returnObject;
}
