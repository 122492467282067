import { config, library } from "@fortawesome/fontawesome-svg-core";
import {
    faAngleDoubleLeft,
    faAngleDown,
    faAngleUp,
    faAnglesDown,
    faAnglesUp,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBox,
    faCalendarAlt,
    faCamera,
    faChartLine,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faClipboard,
    faClipboardCheck,
    faClock,
    faClockRotateLeft,
    faCommentDots,
    faCompressArrowsAlt,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faExclamationTriangle,
    faExpandArrowsAlt,
    faEye,
    faFile,
    faFileExcel,
    faFileExport,
    faFileImport,
    faFloppyDisk,
    faGear,
    faGlobeEurope,
    faGrip,
    faHistory,
    faHome,
    faKey,
    faLanguage,
    faLightbulb,
    faLink,
    faMapPin,
    faPaperPlane,
    faPause,
    faPen,
    faPhoneAlt,
    faPlay,
    faPlug,
    faPlus,
    faQrcode,
    faQuestionCircle,
    faRepeat,
    faRightFromBracket,
    faRightToBracket,
    faSignInAlt,
    faSignature,
    faSortNumericUp,
    faTasks,
    faTicket,
    faTowerCell,
    faTrash,
    faTrashAlt,
    faTruck,
    faTruckLoading,
    faTv,
    faUser,
    faUserCheck,
    faUserCircle,
    faUserLock,
    faUserPlus,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = true;

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(
    faAngleDoubleLeft,
    faAngleDown,
    faAngleUp,
    faAnglesDown,
    faAnglesUp,
    faArrowLeft,
    faArrowRight,
    faBan,
    faBox,
    faCalendarAlt,
    faCamera,
    faChartLine,
    faCheck,
    faCheckDouble,
    faChevronDown,
    faChevronUp,
    faClipboard,
    faClipboardCheck,
    faClock,
    faClockRotateLeft,
    faCommentDots,
    faCompressArrowsAlt,
    faDownload,
    faEdit,
    faEllipsisV,
    faEnvelope,
    faExclamationTriangle,
    faExpandArrowsAlt,
    faEye,
    faFile,
    faFileExcel,
    faFileExport,
    faFileImport,
    faFloppyDisk,
    faGlobeEurope,
    faHistory,
    faHome,
    faKey,
    faLanguage,
    faLink,
    faPaperPlane,
    faPause,
    faPen,
    faPhoneAlt,
    faPlay,
    faPlug,
    faPlus,
    faQuestionCircle,
    faRepeat,
    faSignInAlt,
    faSignature,
    faSortNumericUp,
    faTasks,
    faTowerCell,
    faTrash,
    faTrashAlt,
    faTruck,
    faTruckLoading,
    faTv,
    faUser,
    faUserCheck,
    faUserCircle,
    faUserLock,
    faUserPlus,
    faXmark,
    faMapPin,
    faQrcode,
    faLightbulb,
    faTicket,
    faGear,
    faGrip,
    faRightToBracket,
    faRightFromBracket
);

// Register the component globally

export default defineNuxtPlugin((nuxtApp) => {
    // now available on `nuxtApp.$injected`
    nuxtApp.vueApp.component("FontAwesomeIcon", FontAwesomeIcon);
});
