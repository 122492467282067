import payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k from "/code/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY from "/code/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q from "/code/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/code/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_so_mZLLgH5kwBRJF8GQ1dEBM80gkgZwsJe_CGBY5t_E from "/code/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import i18n_epriZX5TR20CjxNaNMNH6tuF61OTniRuh36sqjODWS0 from "/code/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import switch_locale_path_ssr_Hk9vdM15aB_0VkdtA_Usx_xFCjk41oE2KO5i9C8RnMs from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_9xnjrvR2gs_Q4sLywtkwzH8IawzhEmfdVknj25um3og from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_aNMRN_VpxP5fGsesKfa8RtP7jI8L9qZpzOP_wk7xuAQ from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_r7tfuQg5YFXqYaaOS8ZfNtBw0cpUJdZugt7YHpWY7b8 from "/code/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import ionicElements_client_PtWPLPzpbJPX_1i_9LaajWdTgBWWsry3Y4HNDM0vPYs from "/code/frontend/plugins/ionicElements.client.ts";
import fontawesome_client_AMDH1Mn1xloOMAYxTtt61gLI_ovo750N7AmE3pDTs0w from "/code/frontend/plugins/fontawesome.client.ts";
import bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI from "/code/frontend/plugins/bootstrap.client.ts";
import apm_client_YQtUpR_uQ9d1iRkMVfLdc1lwFnCTfgp2RLcC48jt05I from "/code/frontend/plugins/apm.client.ts";
import maska_client_S3IzAdLxWk0etTyewKC8joEhTFs4vJhk9SCo4w33ydY from "/code/frontend/plugins/maska.client.ts";
import opentelemetry_client_tvraUcJdgG2ta_mJ7__C5cFPsrYKUakTwXeO4nQqIuE from "/code/frontend/plugins/opentelemetry.client.ts";
import ssg_detect_UF4Nst39t0UMaSCKhdiI3ti9uHUzHwDPgMWmwbxO5Uw from "/code/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k,
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  _0_siteConfig_E2CmUpC0QPEIjdkp_3WpSPBzcFpkIbxeGjimKojgshY,
  payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  plugin_so_mZLLgH5kwBRJF8GQ1dEBM80gkgZwsJe_CGBY5t_E,
  i18n_epriZX5TR20CjxNaNMNH6tuF61OTniRuh36sqjODWS0,
  switch_locale_path_ssr_Hk9vdM15aB_0VkdtA_Usx_xFCjk41oE2KO5i9C8RnMs,
  route_locale_detect_9xnjrvR2gs_Q4sLywtkwzH8IawzhEmfdVknj25um3og,
  i18n_aNMRN_VpxP5fGsesKfa8RtP7jI8L9qZpzOP_wk7xuAQ,
  plugin_r7tfuQg5YFXqYaaOS8ZfNtBw0cpUJdZugt7YHpWY7b8,
  ionicElements_client_PtWPLPzpbJPX_1i_9LaajWdTgBWWsry3Y4HNDM0vPYs,
  fontawesome_client_AMDH1Mn1xloOMAYxTtt61gLI_ovo750N7AmE3pDTs0w,
  bootstrap_client_c_fMhHoCA_KTiu88qzHpLQCaAGpNyyqa3D_a3ozzERI,
  apm_client_YQtUpR_uQ9d1iRkMVfLdc1lwFnCTfgp2RLcC48jt05I,
  maska_client_S3IzAdLxWk0etTyewKC8joEhTFs4vJhk9SCo4w33ydY,
  opentelemetry_client_tvraUcJdgG2ta_mJ7__C5cFPsrYKUakTwXeO4nQqIuE,
  ssg_detect_UF4Nst39t0UMaSCKhdiI3ti9uHUzHwDPgMWmwbxO5Uw
]